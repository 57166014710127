import React from 'react'
import { Link } from 'gatsby'
import IosSunnyOutline from 'react-ionicons/lib/IosSunnyOutline'
import IosMoonOutline from 'react-ionicons/lib/IosMoonOutline'
import logo from '../img/logo.svg'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-light"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              to="/"
              activeClassName="is-active"
              className="navbar-item"
              title="Home"
            >
              <img src={logo} alt="logo" width="30" height="30" />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered">
              <Link
                className="navbar-item "
                to="/day"
                activeClassName="is-active"
              >
                <div className="columns is-mobile is-vcentered column">
                  <IosSunnyOutline />
                  <span>&nbsp;Day</span>
                </div>
              </Link>
              <Link
                className="navbar-item"
                to="/blog"
                activeClassName="is-active"
              >
                <div className="columns is-mobile  is-vcentered column">
                  <IosMoonOutline />
                  <span>&nbsp;Night</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
