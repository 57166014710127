import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import SEO from './SEO'
import './all.sass'


const TemplateWrapper = ({ children }) => {
  return (
    <div>
      <SEO article={false} />
      <Navbar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
