import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'

class Footer extends React.Component {
  render() {
    const { data } = this.props
    const { edges: socials } = data.allMarkdownRemark

    return (
      <footer className="footer container is-max-desktop px-4">
        <section className="social has-text-centered">
          {socials &&
            socials.map(({ node: social }) => (
              <a
                key={social.id}
                title={social.frontmatter.title}
                href={social.frontmatter.url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="fas fa-lg"
                  src={social.frontmatter.icon.publicURL}
                  alt={social.frontmatter.title}
                  style={{ width: '2em', height: '2em' }}
                />
              </a>
            ))}
        </section>
      </footer>
    )
  }
}

Footer.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query SocialMediaQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "social" } } }
          sort: { order: ASC, fields: frontmatter___title }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                url
                icon {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer data={data} />}
  />
)
